import React from "react";

const PublicPrivacyPolicy = () => {
  return (
    <div className="w-full bg-white">
      {/* Header Section */}
      <div className="w-full bg-white py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Privacy Policy
          </h1>
          <p className="text-xl text-gray-600">
            How we collect, use, and protect your information
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto px-4 pb-16">
        <div className="prose max-w-none">
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              1. Information We Collect
            </h2>
            <p className="text-gray-600 mb-4">
              We collect information that you provide directly to us, including:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li className="mb-2">
                Account information (email address, username, password)
              </li>
              <li className="mb-2">
                Usage data (shortened URLs, click statistics)
              </li>
              <li className="mb-2">Device and browser information</li>
              <li className="mb-2">IP addresses and location data</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              2. How We Use Your Information
            </h2>
            <p className="text-gray-600 mb-4">
              We use the collected information for the following purposes:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li className="mb-2">
                Providing and maintaining our URL shortening service
              </li>
              <li className="mb-2">
                Analyzing usage patterns and improving our service
              </li>
              <li className="mb-2">
                Communicating with you about service updates
              </li>
              <li className="mb-2">Ensuring the security of our platform</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              3. Data Storage and Security
            </h2>
            <p className="text-gray-600 mb-4">
              We implement appropriate technical and organizational measures to
              protect your personal information against unauthorized access,
              alteration, disclosure, or destruction. While we strive to use
              commercially acceptable means to protect your information, we
              cannot guarantee its absolute security.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              4. Cookies and Tracking
            </h2>
            <p className="text-gray-600 mb-4">
              We use cookies and similar tracking technologies to track activity
              on our service and hold certain information. Cookies are files
              with small amounts of data that may include an anonymous unique
              identifier. You can instruct your browser to refuse all cookies or
              to indicate when a cookie is being sent.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              5. Third-Party Services
            </h2>
            <p className="text-gray-600 mb-4">
              Our service may contain links to third-party websites or services
              that are not owned or controlled by Shrtly. We have no control
              over and assume no responsibility for the content, privacy
              policies, or practices of any third-party sites or services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              6. Data Retention
            </h2>
            <p className="text-gray-600 mb-4">
              We will retain your personal information only for as long as
              necessary to fulfill the purposes outlined in this Privacy Policy.
              We will retain and use your information to the extent necessary to
              comply with our legal obligations, resolve disputes, and enforce
              our policies.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              7. Your Rights
            </h2>
            <p className="text-gray-600 mb-4">You have the right to:</p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li className="mb-2">Access your personal data</li>
              <li className="mb-2">Correct inaccurate data</li>
              <li className="mb-2">Request deletion of your data</li>
              <li className="mb-2">Object to data processing</li>
              <li className="mb-2">Request data portability</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              8. Changes to This Policy
            </h2>
            <p className="text-gray-600 mb-4">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page
              and updating the effective date.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              9. Contact Us
            </h2>
            <p className="text-gray-600 mb-4">
              If you have any questions about this Privacy Policy, please
              contact us at privacy@shrtly.net
            </p>
            <p className="text-gray-600 mt-8">
              Last updated: December 12, 2024
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PublicPrivacyPolicy;
