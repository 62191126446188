import React, { useState } from "react";
import { Check } from "lucide-react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PlanCard = ({
  title,
  price,
  period,
  yearlyPrice,
  minValue,
  maxValue,
  description,
  features,
  buttonText,
  isPopular,
}) => (
  <div
    className={`bg-white rounded-lg shadow-lg p-6 transition-all duration-300 hover:shadow-xl relative ${
      isPopular ? "border-2 border-blue-500" : ""
    }`}
  >
    {isPopular && (
      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
        <span className="bg-blue-500 text-white text-sm px-4 py-1 rounded-full">
          Most Popular
        </span>
      </div>
    )}
    <h3 className="text-xl font-bold mb-4">{title}</h3>
    <div className="mb-6">
      <span className="text-4xl font-bold">{price}</span>
      <span className="text-gray-600">{period}</span>
      {yearlyPrice && (
        <div className="text-sm text-gray-500 mt-1">
          {yearlyPrice}
          <span className="text-green-500 ml-2">Save 20%</span>
        </div>
      )}
    </div>

    {minValue && (
      <div className="flex justify-between items-center mb-6">
        <span>{minValue}</span>
        <div className="w-48 h-2 bg-gray-200 rounded-full">
          <div
            className="h-2 bg-blue-500 rounded-full"
            style={{ width: isPopular ? "50%" : "25%" }}
          ></div>
        </div>
        <span>{maxValue}</span>
      </div>
    )}

    <p className="mb-6 text-gray-600">{description}</p>

    {features && (
      <div className="space-y-3 mb-8">
        {features.map((feature, index) => (
          <div key={index} className="flex items-start gap-2">
            <Check className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
            <span className="text-gray-700">{feature}</span>
          </div>
        ))}
      </div>
    )}

    <button
      className={`w-full py-2 px-4 rounded transition-colors duration-300 ${
        buttonText === "Contact Us"
          ? "bg-gray-600 hover:bg-gray-700 text-white"
          : "bg-green-600 hover:bg-green-700 text-white"
      }`}
      onClick={() => toast.warn("This plan is not ready yet. Coming soon!")}
    >
      {buttonText}
    </button>
  </div>
);

PlanCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  period: PropTypes.string,
  yearlyPrice: PropTypes.string,
  minValue: PropTypes.string,
  maxValue: PropTypes.string,
  description: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string.isRequired,
  isPopular: PropTypes.bool,
};

PlanCard.defaultProps = {
  title: "",
  price: "",
  period: "",
  yearlyPrice: "",
  minValue: "",
  maxValue: "",
  features: [],
  isPopular: false,
};

const Plans = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  return (
    <div className="min-h-screen max-w-8xl mx-auto p-8 bg-white">
      <h1 className="text-4xl font-bold mb-4">Shrtly Plans</h1>
      <h2 className="text-2xl text-gray-700 mb-8">
        Find a plan that meets your needs
      </h2>

      <div className="flex items-center gap-4 mb-12">
        <span className="text-gray-600">Billing cycle:</span>
        <span className={!isAnnual ? "font-semibold" : ""}>Monthly</span>
        <button
          className={`w-16 h-8 rounded-full p-1 transition-colors duration-300 ${
            isAnnual ? "bg-blue-500" : "bg-gray-300"
          }`}
          onClick={() => setIsAnnual(!isAnnual)}
        >
          <div
            className={`w-6 h-6 rounded-full bg-white transform transition-transform duration-300 ${
              isAnnual ? "translate-x-8" : ""
            }`}
          />
        </button>
        <span className={isAnnual ? "font-semibold" : ""}>Annual</span>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <PlanCard
          title="Free"
          price="$0"
          period="/mo"
          minValue="0"
          maxValue="100"
          description="Start shortening URLs for free"
          features={[
            "Up to 100 links",
            "Basic Analytics",
            "14-day link expiration",
            "Standard support",
            "Basic link management",
            "Basic link management",
          ]}
          buttonText="Get Started"
        />

        <PlanCard
          title="Pro"
          price={isAnnual ? "$99.90" : "$9.99"}
          period="/mo"
          yearlyPrice={isAnnual ? "($1,198.80/yr)" : "($119.88/yr)"}
          minValue="500"
          maxValue="50K+"
          description="500 Links with Unlimited Trackable Clicks"
          features={[
            "Link Analytics",
            "Advanced Link Management",
            "Shorten URLs Using Branded Domains",
            "Link Editing & Deletion",
            "Custom Link Expiration Dates",
          ]}
          buttonText="Select"
          isPopular={true}
        />

        <PlanCard
          title="Bulk 100K"
          price={isAnnual ? "$990.00" : "$99.00"}
          period="/mo"
          yearlyPrice={isAnnual ? "($11,880.00/yr)" : "($1,188.00/yr)"}
          minValue="100K"
          maxValue="5M+"
          description="100K Links and Track up to 100K Clicks"
          features={[
            "All Pro features included",
            "90-day default link expiration",
            "Track up to 100K clicks",
            "100K branded short links",
            "Priority support",
          ]}
          buttonText="Select"
        />

        <PlanCard
          title="Enterprise"
          price="Custom"
          description="Need a larger limit, dedicated customer support, custom solutions, or specific compliance requirements?"
          features={[
            "Dedicated account manager",
            "Custom link limits",
            "Advanced security features",
            "Custom integration support",
            "SLA guarantee",
          ]}
          buttonText="Contact Us"
        />
      </div>
    </div>
  );
};

export default Plans;
