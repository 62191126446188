// src/App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import UrlList from "./components/UrlList";
import Plans from "./components/Plans";
import Features from "./components/Features";
import UrlDetails from "./components/UrlDetails";
import { SignIn, SignUp } from "./components/AuthLayout";
import VerificationPending from "./components/VerificationPending";
import VerifyEmail from "./components/VerifyEmail";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicTermsOfService from "./components/PublicTermsOfService";
import PublicPrivacyPolicy from "./components/PublicPrivacyPolicy";
import Navbar from "./components/Navbar";
import { AuthProvider } from "./components/context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PublicAccessibility from "./components/PublicAccessibility";

const App = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route
                path="/verify-pending/:emailAddress"
                element={<VerificationPending />}
              />
              <Route path="/verify-email" element={<VerifyEmail />} />

              <Route index element={<Home />} />
              <Route
                path="/urls"
                element={
                  <ProtectedRoute>
                    <UrlList />
                  </ProtectedRoute>
                }
              />
              <Route path="/details/:shortCode" element={<UrlDetails />} />
              <Route path="/plans" element={<Plans />} />
              <Route path="/features" element={<Features />} />
              <Route
                path="/public-privacy-policy"
                element={<PublicPrivacyPolicy />}
              />
              <Route
                path="/public-terms-of-service"
                element={<PublicTermsOfService />}
              />
              <Route
                path="/public-accessibility"
                element={<PublicAccessibility />}
              />
              <Route path="/how-it-works" element={<Features />} />
            </Route>
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </AuthProvider>
    </>
  );
};

export default App;
