import React from "react";
import {
  QrCode,
  Share2,
  Copy,
  Trash2,
  RefreshCw,
  Search,
  Pencil,
  FolderPen,
  Replace,
  Save,
  Ban,
  Eye,
} from "lucide-react";
import { timeAgo } from "../helpers/datetime/timeAgo";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { urlService } from "../services/urlService";
import ShareDropdown from "./helpers/ShareDropdown";
import { useNavigate } from "react-router-dom";

const UrlList = () => {
  const navigate = useNavigate();

  const [urls, setUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editType, setEditType] = useState(null); // 'shortCode' or 'originalUrl'
  const [editValue, setEditValue] = useState("");
  const [shareOpen, setShareOpen] = useState(null); // Add to your state declarations

  useEffect(() => {
    fetchUrls();
  }, []);

  const fetchUrls = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await urlService.getAllUrls();
      setUrls(data);
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const searchUrls = async (query) => {
    try {
      setLoading(true);
      setError(null);
      const data = await urlService.searchUrls(query);
      setUrls(data);
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const timeout = setTimeout(() => {
      if (searchQuery) {
        searchUrls(searchQuery);
      } else {
        fetchUrls();
      }
    }, 500);

    setSearchTimeout(timeout);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [searchQuery]);

  const deleteUrl = async (id) => {
    try {
      setLoading(true);
      await urlService.deleteUrl(id);
      await fetchUrls();
      toast.success("URL deleted successfully!");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    toast.success("URL copied successfully!");
  };

  const startEditing = (url, type) => {
    setEditingId(url._id);
    setEditType(type);
    setEditValue(type === "shortCode" ? url.shortCode : url.originalUrl);
  };

  const cancelEditing = () => {
    setEditingId(null);
    setEditType(null);
    setEditValue("");
  };

  useEffect(() => {
    const handleClickOutside = () => setShareOpen(null);
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleUpdate = async (id) => {
    try {
      setLoading(true);
      const updateData =
        editType === "shortCode"
          ? { shortCode: editValue }
          : { originalUrl: editValue };

      await urlService.updateUrl(id, updateData);
      await fetchUrls();
      cancelEditing();
      toast.success(`URL ${editType} updated successfully!`);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderEditForm = (url) => (
    <div className="absolute inset-0 bg-black/50 backdrop-blur-sm rounded-lg flex items-center justify-center p-4">
      <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md">
        <h3 className="text-white text-lg font-medium mb-4">
          Edit {editType === "shortCode" ? "Short Code" : "Original URL"}
        </h3>
        <input
          type="text"
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          className="w-full bg-white/10 text-white border border-white/20 rounded-lg py-2 px-4 mb-4 focus:outline-none focus:ring-2 focus:ring-purple-500"
        />
        <div className="flex gap-2 justify-end">
          <button
            onClick={cancelEditing}
            className="px-4 py-2 rounded-md bg-white/10 hover:bg-white/20 text-white transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => handleUpdate(url._id)}
            className="px-4 py-2 rounded-md bg-purple-500 hover:bg-purple-600 text-white transition-colors"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="container mx-auto p-6 text-center text-white">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white mx-auto"></div>
        <p className="mt-4">Loading URLs...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-6 text-center text-red-400">
        <p>Error: {error}</p>
        <button
          onClick={fetchUrls}
          className="mt-4 px-4 py-2 bg-white/10 hover:bg-white/20 rounded-md text-white transition-colors"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        <h2 className="text-2xl font-semibold text-white">
          Your recent shrtlys
        </h2>
        <div className="relative w-full md:w-96">
          <input
            type="text"
            placeholder="Search URLs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full bg-white/5 text-white border border-white/10 rounded-lg py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
          />
          <Search className="absolute left-3 top-2.5 text-white/60" size={20} />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {urls.map((url) => (
          <div
            key={url._id}
            className="bg-white/5 backdrop-blur-lg rounded-lg p-6 hover:bg-white/10 transition-colors"
          >
            <div className="flex items-start gap-4 mb-4">
              <div className="w-12 h-12 bg-purple-500 rounded-full flex items-center justify-center text-white text-xl font-semibold flex-shrink-0">
                {url.customDomain.charAt(0).toUpperCase()}
              </div>
              <div className="min-w-0 w-full">
                <h3 className="text-white text-lg font-medium truncate">
                  {url.customDomain}/
                  {editingId === url._id && editType === "shortCode" ? (
                    <input
                      type="text"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleUpdate(url._id);
                        if (e.key === "Escape") cancelEditing();
                      }}
                      className="bg-white/10 text-white border border-white/20 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-purple-500 w-24 inline-block"
                      autoFocus
                    />
                  ) : (
                    url.shortCode
                  )}
                </h3>
                <p className="text-white/60 text-sm">
                  {editingId === url._id && editType === "originalUrl" ? (
                    <input
                      type="text"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleUpdate(url._id);
                        if (e.key === "Escape") cancelEditing();
                      }}
                      className="bg-white/10 text-white border border-white/20 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-purple-500 w-full"
                      autoFocus
                    />
                  ) : (
                    <span className="truncate block">{url.originalUrl}</span>
                  )}
                </p>
                <p className="text-white/60 text-sm mt-1">
                  {url.totalClicks} clicks • {timeAgo(url.createdAt)}
                </p>
              </div>
            </div>

            <div className="flex flex-wrap gap-2">
              {editingId === url._id ? (
                <>
                  <button
                    onClick={() => handleUpdate(url._id)}
                    className="flex items-center gap-1 p-2 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                    title="Save changes"
                  >
                    <Save size={18} />
                  </button>
                  <button
                    onClick={cancelEditing}
                    className="flex items-center gap-1 p-2 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                    title="Cancel"
                  >
                    <Ban size={18} />
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => startEditing(url, "shortCode")}
                    className="flex items-center gap-1 p-2 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                    title="Edit Short Code"
                  >
                    <Pencil size={18} />
                  </button>
                  <button
                    onClick={() => startEditing(url, "originalUrl")}
                    className="flex items-center gap-1 p-2 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                    title="Edit Original URL"
                  >
                    <Replace size={18} />
                  </button>
                  <button
                    className="flex items-center gap-1 p-2 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                    title="Generate QR Code"
                  >
                    <QrCode size={18} />
                  </button>
                  <ShareDropdown
                    url={url}
                    isOpen={shareOpen === url._id}
                    setIsOpen={(open) => setShareOpen(open ? url._id : null)}
                  />
                  <button
                    onClick={() =>
                      copyUrl(`${url.customDomain}/${url.shortCode}`)
                    }
                    className="flex items-center gap-1 p-2 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                    title="Copy URL"
                  >
                    <Copy size={16} className="mr-1" />
                  </button>
                  <button
                    onClick={() => deleteUrl(url._id)}
                    className="flex items-center gap-1 p-2 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                    title="Delete"
                  >
                    <Trash2 size={18} />
                  </button>
                  <button
                    onClick={() => navigate("/details/" + url.shortCode)}
                    className="flex items-center gap-1 p-2 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                    title="Delete"
                  >
                    <Eye size={18} />
                  </button>
                </>
              )}
            </div>
          </div>
        ))}
      </div>

      {urls.length === 0 && !loading && !error && (
        <div className="text-center text-white/60 py-8">
          You have not shortened any URLs yet.
        </div>
      )}

      {/* <ShareDropdown
        url={url}
        isOpen={shareOpen === url._id}
        setIsOpen={(open) => setShareOpen(open ? url._id : null)}
      /> */}
    </div>
  );
};

export default UrlList;
