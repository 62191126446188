// src/services/urlService.js
import api from "./api";

export const urlService = {
  // Matches createShortUrl controller
  createShortUrl: async (urlData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No authentication token found");
    }

    const { longUrl, customAlias, customDomain } = urlData; // Changed from url to longUrl

    // Ensure URL has protocol (http:// or https://)
    const formattedUrl = longUrl.startsWith("http")
      ? longUrl
      : `https://${longUrl}`;

    return await api.post("urls", {
      url: formattedUrl, // Send as url to match backend expectation
      customAlias: customAlias || "",
      customDomain: customDomain || "https://shrtly.net",
    });
  },

  // New function to get analytics for a specific URL
  getUrlAnalytics: async (shortCode) => {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("No authentication token found");
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/urls/analytics/${shortCode}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to fetch analytics");
    }

    return response.json();
  },

  getAllUrls: async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("No authentication token found");
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/urls`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || "Failed to fetch URLs");
    }

    return response.json();
  },

  searchUrls: async (query) => {
    return await api.get(`/urls/search?q=${encodeURIComponent(query)}`);
  },

  // Matches deleteUrl controller
  deleteUrl: async (id) => {
    return await api.delete(`/urls/${id}`);
  },

  updateUrl: async (id, urlData) => {
    return await api.put(`/urls/${id}`, urlData);
  },
};
