// ShareDropdown.jsx
import React from "react";
import PropTypes from "prop-types";
import {
  Share2,
  Facebook,
  Twitter,
  MessageCircle,
  Linkedin,
  Mail,
} from "lucide-react";

const ShareDropdown = ({ url, isOpen, setIsOpen }) => {
  const shareUrl = `${url.customDomain}/${url.shortCode}`;

  const shareOptions = [
    {
      name: "Facebook",
      icon: <Facebook size={18} />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`,
    },
    {
      name: "Twitter",
      icon: <Twitter size={18} />,
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        shareUrl
      )}`,
    },
    {
      name: "WhatsApp",
      icon: <MessageCircle size={18} />,
      url: `https://wa.me/?text=${encodeURIComponent(shareUrl)}`,
    },
    {
      name: "LinkedIn",
      icon: <Linkedin size={18} />,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        shareUrl
      )}`,
    },
    {
      name: "Email",
      icon: <Mail size={18} />,
      url: `mailto:?body=${encodeURIComponent(shareUrl)}`,
    },
  ];

  return (
    <div className="relative share-dropdown">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="flex items-center gap-1 p-2 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
        title="Share"
      >
        <Share2 size={18} />
      </button>

      {isOpen && (
        <div className="absolute bottom-full left-0 mb-2 w-48 bg-gray-800 rounded-lg shadow-lg py-2 z-50">
          {shareOptions.map((option) => (
            <a
              key={option.name}
              href={option.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-3 px-4 py-2 text-white hover:bg-white/10 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              {option.icon}
              <span>{option.name}</span>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

ShareDropdown.propTypes = {
  url: PropTypes.shape({
    customDomain: PropTypes.string.isRequired,
    shortCode: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default ShareDropdown;
