/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Link2, BarChart2, Settings } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { urlService } from "../services/urlService";
import "react-toastify/dist/ReactToastify.css";
import "../index.css";

const FeatureOption = ({ icon: Icon, title, comingSoon = false }) => (
  <div className="flex items-center justify-between bg-white/10 p-4 rounded-lg transition-all hover:bg-white/20">
    <div className="flex items-center">
      <Icon className="mr-3" size={24} />
      <span>{title}</span>
    </div>
    {comingSoon && (
      <span className="text-xs bg-blue-500 text-white px-2 py-1 rounded">
        Coming Soon
      </span>
    )}
  </div>
);

const Home = () => {
  const navigate = useNavigate();
  const [longUrl, setLongUrl] = useState("");
  const [customAlias, setCustomAlias] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("https://shrtly.net");
  const [isCustomizing, setIsCustomizing] = useState(false);
  const [shortenedUrl, setShortenedUrl] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check for token and decode username from it
    const token = localStorage.getItem("token");
    if (token) {
      try {
        setIsLoggedIn(true);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  const features = [
    { icon: BarChart2, title: "Detailed Link Analytics", comingSoon: false },
    { icon: Link2, title: "Bulk Short URLs", comingSoon: true },
    { icon: Settings, title: "Link Management Features", comingSoon: true },
  ];

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidUrl(longUrl)) {
      return toast.error(
        "Please enter a valid URL. Example: https://example.com"
      );
    }

    try {
      const data = await urlService.createShortUrl({
        longUrl,
        customAlias,
        customDomain: selectedDomain,
      });
      toast.success("URL shortened successfully!");
      setShortenedUrl(data.shortUrl);
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-cyan-900 via-teal-800 to-teal-600">
      <div className="flex-grow max-w-7xl mx-auto px-4 flex flex-col md:flex-row gap-12 mt-16">
        <div className="w-full md:w-1/2">
          <div className="bg-white rounded-lg p-6 shadow-xl">
            <div className="mb-6">
              <div className="flex items-center mb-4">
                <Link2 className="text-teal-600 mr-2" size={20} />
                <h2 className="text-lg font-medium">Shorten a long URL</h2>
              </div>
              <input
                type="url"
                value={longUrl}
                onChange={(e) => setLongUrl(e.target.value)}
                placeholder="Enter long link here"
                className="w-full p-3 border rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
              />
            </div>

            <div className="mb-6">
              <div
                className="flex items-center mb-4 cursor-pointer"
                onClick={() => setIsCustomizing(!isCustomizing)}
              >
                <Settings className="text-teal-600 mr-2" size={20} />
                <h2 className="text-lg font-medium">Customize your link</h2>
              </div>
              {isCustomizing && (
                <div className="flex gap-4">
                  <select
                    value={selectedDomain}
                    onChange={(e) => setSelectedDomain(e.target.value)}
                    className="w-1/2 p-3 border rounded-md focus:ring-2 focus:ring-teal-500"
                  >
                    <option value="shrtly.net">shrtly.net</option>
                    {/* <option value="custom.domain">custom.domain</option> */}
                  </select>
                  <input
                    type="text"
                    value={customAlias}
                    onChange={(e) => setCustomAlias(e.target.value)}
                    placeholder="Enter alias"
                    className="w-1/2 p-3 border rounded-md focus:ring-2 focus:ring-teal-500"
                  />
                </div>
              )}
            </div>

            <button
              onClick={handleSubmit}
              className="w-full bg-teal-600 text-white p-4 rounded-md hover:bg-teal-700 transition-colors font-medium"
            >
              Shorten URL
            </button>

            {shortenedUrl && (
              <div className="mt-4 p-4 bg-teal-50 rounded-md">
                <p className="text-teal-800 font-medium">Your shortened URL:</p>
                <div className="flex items-center gap-2 mt-2">
                  <input
                    type="text"
                    value={shortenedUrl}
                    readOnly
                    className="flex-1 p-2 border rounded"
                  />
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(shortenedUrl);
                      toast.success("URL copied to clipboard!");
                    }}
                    className="bg-teal-600 text-white px-4 py-2 rounded hover:bg-teal-700"
                  >
                    Copy
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="w-full md:w-1/2 text-white">
          <h1 className="text-4xl font-bold mb-4">
            The Original URL Shortener
          </h1>
          <p className="text-xl mb-6">Create shorter URLs with SHRTLY.</p>

          <p className="mb-8 text-teal-100">
            Want more out of your link shortener? Track link analytics, use
            branded domains for fully custom links, and manage your links with
            our paid plans.
          </p>

          <div className="flex gap-4 mb-12">
            <button
              onClick={() => navigate("/plans")}
              className="bg-white text-teal-900 px-8 py-3 rounded-md hover:bg-teal-50 transition-colors font-medium"
            >
              View Plans
            </button>
            {!isLoggedIn && (
              <button
                onClick={() => navigate("/signup")}
                className="border-2 border-white text-white px-8 py-3 rounded-md hover:bg-white/10 transition-colors font-medium"
              >
                Create Free Account
              </button>
            )}
          </div>

          <h3 className="text-xl font-semibold mb-6">SHRTLY plans include:</h3>
          <div className="space-y-4 mb-24">
            {features.map((feature, index) => (
              <FeatureOption key={index} {...feature} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
