import React from "react";
import {
  Camera,
  Link as LinkIcon,
  Globe,
  BarChart,
  HelpCircle,
  Info,
} from "lucide-react";
import PropTypes from "prop-types";

const FeatureCard = React.memo(
  ({ icon: Icon, title, description, comingSoon }) => (
    <div className="relative bg-white rounded-lg shadow p-6 hover:shadow-lg transition-shadow">
      <div className="flex flex-col items-center text-center">
        <Icon size={48} className="mb-4 text-blue-500" />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
      {comingSoon && (
        <div className="absolute inset-0 bg-black/30 rounded-lg flex items-start justify-end p-4">
          <span className="text-white font-semibold text-sm bg-blue-500 px-2 py-1 rounded">
            Coming Soon
          </span>
        </div>
      )}
    </div>
  )
);

FeatureCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  comingSoon: PropTypes.bool,
};

FeatureCard.displayName = "FeatureCard";

// Rest of the component remains the same
const Features = () => {
  const features = [
    {
      icon: LinkIcon,
      title: "Link Management",
      description:
        "Easily manage, edit, and organize all your shortened URLs in one place.",
    },
    {
      icon: Globe,
      title: "Branded & Customized Links",
      description:
        "Create custom branded links that align with your brand identity.",
      comingSoon: true,
    },
    {
      icon: BarChart,
      title: "Custom Branded Domains",
      description:
        "Use your own domain to create branded short links for better brand recognition.",
      comingSoon: true,
    },
    {
      icon: Camera,
      title: "Campaign Monitoring & Analytics",
      description:
        "Track and analyze link performance with comprehensive analytics.",
    },
    {
      icon: HelpCircle,
      title: "How It Works",
      description: "Learn about our URL shortening process and best practices.",
    },
    {
      icon: Info,
      title: "About Shrtly",
      description: "Discover our story and mission to simplify link sharing.",
    },
  ];

  return (
    <div className="min-h-screen max-w-8xl mx-auto p-8 bg-white">
      <h1 className="text-4xl font-bold mb-4">Features</h1>
      <p className="text-xl text-gray-600 mb-12">
        Discover what Shrtly can do for you
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </div>
  );
};

export default Features;
