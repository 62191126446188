import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="bg-transparent p-4 text-white py-4">
      <div className="max-w-7xl mx-auto px-4 text-white text-sm flex items-center justify-between">
        <div>
          <a
            href="https://www.youtube.com/c/dotnethow"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-gray-900 cursor-pointer"
          >
            © Ervis Trupja
          </a>
        </div>
        <div className="flex gap-4">
          <a
            onClick={() => navigate("/public-terms-of-service")}
            className="hover:text-gray-900 cursor-pointer"
          >
            Terms
          </a>
          <a
            onClick={() => navigate("/public-privacy-policy")}
            className="hover:text-gray-900 cursor-pointer"
          >
            Privacy Policy
          </a>
          <a
            onClick={() => navigate("/public-accessibility")}
            className="hover:text-gray-900 cursor-pointer"
          >
            Accessibility
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
