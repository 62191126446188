// AuthLayout.js
import React from "react";
import PropTypes from "prop-types";
import { Eye, X, Mail, Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";
import { useAuth } from "./context/AuthContext"; // Adjust path based on your structure
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Custom Alert Component
const Alert = ({ variant = "default", children }) => {
  const bgColor = variant === "destructive" ? "bg-red-100" : "bg-blue-100";
  const textColor =
    variant === "destructive" ? "text-red-700" : "text-blue-700";

  return (
    <div className={`p-4 ${bgColor} ${textColor} rounded-lg`}>{children}</div>
  );
};

Alert.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const AuthLayout = ({ children }) => (
  <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
    <div className="w-full max-w-md bg-white rounded-lg shadow p-8 relative">
      <button className="absolute right-4 top-4 text-gray-400 hover:text-gray-600">
        <X size={24} />
      </button>
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-cyan-600 mb-4">SHRLY</h1>
        <h2 className="text-xl text-gray-700">Welcome to Shrtly</h2>
      </div>
      {children}
    </div>
  </div>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const SignIn = () => {
  const navigate = useNavigate();

  const { login } = useAuth(); // Adjust based on your structure

  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await authService.login(email, password);

      console.log("login (response)  --- ", response);
      console.log("login (response.ok)  --- ", response.ok);

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || "Login failed");
      }

      const data = await response.json();
      const userInfo = data;

      login(data.token, userInfo);
      navigate("/urls");
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <form className="space-y-6" onSubmit={handleSubmit}>
        {error && <Alert variant="destructive">{error}</Alert>}

        <div>
          <label className="block text-gray-600 mb-2">Email</label>
          <div className="relative">
            <Mail
              className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
              size={20}
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pl-10 p-2 border rounded focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-gray-600 mb-2">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400"
            >
              <Eye size={20} />
            </button>
          </div>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700 flex items-center justify-center disabled:bg-cyan-300"
        >
          {loading ? (
            <Loader2 className="animate-spin" size={20} />
          ) : (
            <>
              <span className="mr-2">→</span> Sign In
            </>
          )}
        </button>
      </form>

      <p className="text-center mt-6">
        Don&apos;t have an account?{" "}
        <button
          onClick={() => navigate("/signup")}
          className="text-cyan-600 hover:underline"
        >
          Sign Up
        </button>
      </p>
    </AuthLayout>
  );
};

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await authService.signup(
        formData.email,
        formData.password
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Registration failed");
      }

      toast.success(
        "Registration successful! Please check your email for verification link.",
        { duration: 5000 }
      );

      // Optional: Redirect to a verification pending page
      setTimeout(() => {
        navigate("/verify-pending/" + formData.email);
      }, 5000);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <form className="space-y-6" onSubmit={handleSubmit}>
        {error && <Alert variant="destructive">{error}</Alert>}

        <div>
          <label className="block text-gray-600 mb-2">Email</label>
          <div className="relative">
            <Mail
              className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
              size={20}
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full pl-10 p-2 border rounded focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-gray-600 mb-2">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-cyan-500 focus:border-transparent"
              required
              minLength={6}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400"
            >
              <Eye size={20} />
            </button>
          </div>
          <p className="text-sm text-gray-500 mt-1">
            Must be at least 6 characters long
          </p>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="w-full bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700 flex items-center justify-center disabled:bg-cyan-300"
        >
          {loading ? (
            <Loader2 className="animate-spin" size={20} />
          ) : (
            <>
              <span className="mr-2">→</span> Create Account
            </>
          )}
        </button>
      </form>

      <p className="text-center mt-6">
        Already have an account?{" "}
        <button
          onClick={() => navigate("/signin")}
          className="text-cyan-600 hover:underline"
        >
          Sign In
        </button>
      </p>
    </AuthLayout>
  );
};

export { SignIn, SignUp };
