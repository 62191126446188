// src/components/Navbar.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogOut, User } from "lucide-react";
import { useAuth } from "./context/AuthContext";

const Navbar = () => {
  const { isAuthenticated, logout, userInfo } = useAuth();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    // Check for token and decode username from it
    const token = localStorage.getItem("token");
    if (token) {
      try {
        // Decode the JWT token to get the username
        const payload = JSON.parse(atob(token.split(".")[1]));
        setUsername(payload.username);
        setIsLoggedIn(true);
      } catch (error) {
        console.error("Error decoding token:", error);
        handleLogout(); // Clear invalid token
      }
    }
  }, []);

  const handleLogout = () => {
    logout();

    localStorage.removeItem("token");
    setIsLoggedIn(false);
    setUsername("");
    navigate("/");
  };

  const getUsername = (email) => {
    return email?.split("@")[0] || "";
  };

  return (
    <nav className="bg-transparent p-4">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <button
          className="text-white text-3xl font-bold tracking-wider"
          onClick={() => navigate("/")}
        >
          SHRTLY
        </button>
        <div className="flex items-center gap-6">
          {isAuthenticated ? (
            <>
              <button
                onClick={() => navigate("/urls")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                My URLs
              </button>
              <button
                onClick={() => navigate("/plans")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                Plans
              </button>
              <button
                onClick={() => navigate("/features")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                Features
              </button>
              {/* User Profile Section */}
              <div className="flex items-center gap-3 text-white">
                <div className="flex items-center gap-2">
                  <User size={20} className="text-teal-300" />
                  <span className="text-teal-100">
                    {userInfo?.userEmail ? getUsername(userInfo.userEmail) : ""}
                  </span>
                </div>
                <button
                  onClick={handleLogout}
                  className="flex items-center gap-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors"
                >
                  <LogOut size={18} />
                  Logout
                </button>
              </div>
            </>
          ) : (
            <>
              <button
                onClick={() => navigate("/features")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                Features
              </button>
              <button
                onClick={() => navigate("/plans")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                Plans
              </button>
              <button
                onClick={() => navigate("/signup")}
                className="bg-teal-500 text-white px-4 py-2 rounded-md hover:bg-teal-600 transition-colors"
              >
                Sign Up
              </button>
              <button
                onClick={() => navigate("/signin")}
                className="text-white hover:text-teal-200 transition-colors"
              >
                Sign In
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
