import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Copy, ExternalLink } from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { timeAgo } from "../helpers/datetime/timeAgo";
import { urlService } from "../services/urlService";

const UrlDetails = () => {
  const { shortCode } = useParams();
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        const data = await urlService.getUrlAnalytics(shortCode);
        setAnalytics(data);
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (shortCode) {
      fetchAnalytics();
    }
  }, [shortCode]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  };

  if (loading) {
    return (
      <div className="container mx-auto p-6 text-center text-white">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white mx-auto"></div>
        <p className="mt-4">Loading analytics...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-6 text-center text-red-400">
        <p>Error: {error}</p>
      </div>
    );
  }

  if (!analytics) {
    return (
      <div className="container mx-auto p-6 text-center text-white">
        <p>No analytics data available</p>
      </div>
    );
  }

  // Prepare data for charts
  const browserData = Object.entries(analytics.clicksByBrowser).map(
    ([browser, count]) => ({
      name: browser,
      clicks: count,
    })
  );

  const osData = Object.entries(analytics.clicksByOS).map(([os, count]) => ({
    name: os,
    clicks: count,
  }));

  const countryData = Object.entries(analytics.clicksByCountry).map(
    ([country, count]) => ({
      name: country || "Unknown",
      clicks: count,
    })
  );

  const timelineData = analytics.clicksByTime.map((click) => ({
    timestamp: new Date(click.timestamp).toLocaleDateString(),
    clicks: 1,
    country: click.country || "Unknown",
  }));

  return (
    <div className="container mx-auto p-6">
      {/* URL Overview Card */}
      <div className="bg-white/5 rounded-lg p-6 mb-6">
        <h1 className="text-2xl font-bold text-white mb-6">
          Analytics Overview
        </h1>

        <div className="space-y-6">
          {/* Short URL Section */}
          <div className="flex flex-col space-y-2">
            <label className="text-white/60 text-sm">Short URL</label>
            <div className="flex items-center gap-2">
              <a
                href={analytics.urlDetails.fullShortUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-400 hover:text-purple-300 truncate"
              >
                {analytics.urlDetails.fullShortUrl}
              </a>
              <button
                onClick={() =>
                  copyToClipboard(analytics.urlDetails.fullShortUrl)
                }
                className="p-1.5 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                title="Copy short URL"
              >
                <Copy size={16} />
              </button>
              <a
                href={analytics.urlDetails.fullShortUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="p-1.5 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                title="Open in new tab"
              >
                <ExternalLink size={16} />
              </a>
            </div>
          </div>

          {/* Original URL Section */}
          <div className="flex flex-col space-y-2">
            <label className="text-white/60 text-sm">Original URL</label>
            <div className="flex items-center gap-2">
              <a
                href={analytics.urlDetails.originalUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white/80 hover:text-white truncate"
              >
                {analytics.urlDetails.originalUrl}
              </a>
              <button
                onClick={() =>
                  copyToClipboard(analytics.urlDetails.originalUrl)
                }
                className="p-1.5 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                title="Copy original URL"
              >
                <Copy size={16} />
              </button>
              <a
                href={analytics.urlDetails.originalUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="p-1.5 rounded-md bg-white/5 hover:bg-white/10 text-white/80 hover:text-white transition-colors"
                title="Open in new tab"
              >
                <ExternalLink size={16} />
              </a>
            </div>
          </div>

          {/* Stats Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
            <div className="bg-white/5 rounded-lg p-4">
              <p className="text-white/60 text-sm">Total Clicks</p>
              <p className="text-2xl font-bold text-white">
                {analytics.totalClicks.toLocaleString()}
              </p>
            </div>

            <div className="bg-white/5 rounded-lg p-4">
              <p className="text-white/60 text-sm">Created</p>
              <p className="text-white">
                {timeAgo(analytics.urlDetails.createdAt)}
              </p>
            </div>

            <div className="bg-white/5 rounded-lg p-4">
              <p className="text-white/60 text-sm">Custom Domain</p>
              <p className="text-white">{analytics.urlDetails.customDomain}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Analytics Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Browser Distribution */}
        <div className="bg-white rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Browsers</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={browserData}>
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis dataKey="name" stroke="#FFFFFF80" />
                <YAxis stroke="#FFFFFF80" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#1F2937",
                    border: "none",
                    borderRadius: "0.5rem",
                    color: "#FFFFFF",
                  }}
                />
                <Bar dataKey="clicks" fill="#8B5CF6" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Operating System Distribution */}
        <div className="bg-white rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Operating Systems</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={osData}>
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis dataKey="name" stroke="#FFFFFF80" />
                <YAxis stroke="#FFFFFF80" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#1F2937",
                    border: "none",
                    borderRadius: "0.5rem",
                    color: "#FFFFFF",
                  }}
                />
                <Bar dataKey="clicks" fill="#10B981" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Country Distribution */}
        <div className="bg-white rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Countries</h2>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={countryData}>
                <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
                <XAxis dataKey="name" stroke="#FFFFFF80" />
                <YAxis stroke="#FFFFFF80" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#1F2937",
                    border: "none",
                    borderRadius: "0.5rem",
                    color: "#FFFFFF",
                  }}
                />
                <Bar dataKey="clicks" fill="#F59E0B" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Recent Clicks */}
        <div className="bg-white rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Recent Clicks</h2>
          <div className="space-y-4 max-h-64 overflow-y-auto">
            {analytics.recentClicks.map((click, index) => (
              <div key={index} className="bg-white/10 p-4 rounded-lg">
                <p>Time: {new Date(click.timestamp).toLocaleString()}</p>
                <p>Browser: {click.device?.browser || "Unknown"}</p>
                <p>OS: {click.device?.os || "Unknown"}</p>
                <p>
                  Location:{" "}
                  {click.location?.city && click.location?.country
                    ? `${click.location.city}, ${click.location.country}`
                    : "Unknown"}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UrlDetails;
