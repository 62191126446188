// src/components/VerificationPending.js
import React, { useState } from "react";
import { Mail, Loader2 } from "lucide-react";
import PropTypes from "prop-types";
import { authService } from "../services/authService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Simple Alert Component
const Alert = ({ children }) => (
  <div className="p-4 bg-blue-100 text-blue-700 rounded-lg">{children}</div>
);

Alert.propTypes = {
  children: PropTypes.node.isRequired,
};

// Main VerificationPending Component
function VerificationPending() {
  const { emailAddress } = useParams();

  const [resending, setResending] = useState(false);
  const [message, setMessage] = useState("");

  const handleResendEmail = async () => {
    setResending(true);
    setMessage("");

    try {
      const response = await authService.resendVerification(emailAddress);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to resend verification email");
      }

      toast.success(
        "Verification email has been resent. Please check your inbox."
      );
    } catch (error) {
      // setMessage("Failed to resend verification email. Please try again.");
      toast.error(error.message);
    } finally {
      setResending(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow p-8">
        <div className="text-center">
          <div className="mx-auto w-16 h-16 bg-cyan-100 rounded-full flex items-center justify-center mb-4">
            <Mail className="text-cyan-600" size={32} />
          </div>
          <h1 className="text-2xl font-bold text-gray-900 mb-2">
            Verify your email address
          </h1>
          <p className="text-sm text-gray-500 mb-2">
            {emailAddress || "Email address"}
          </p>
          <p className="text-gray-600 mb-6">
            Weve sent a verification link to your email address. Please click
            the link to verify your account.
          </p>

          {message && <Alert className="mb-6">{message}</Alert>}

          <div className="space-y-4">
            <button
              onClick={handleResendEmail}
              disabled={resending}
              className="w-full bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700 flex items-center justify-center disabled:bg-cyan-300"
            >
              {resending ? (
                <Loader2 className="animate-spin" size={20} />
              ) : (
                "Resend verification email"
              )}
            </button>

            <a
              href="/signin"
              className="block w-full p-2 border border-cyan-600 text-cyan-600 rounded hover:bg-cyan-50 text-center"
            >
              Return to Sign In
            </a>
          </div>

          <p className="mt-6 text-sm text-gray-500">
            Didnt receive the email? Check your spam folder or contact support.
          </p>
        </div>
      </div>
    </div>
  );
}

export default VerificationPending;
