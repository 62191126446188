import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Mail, Loader2, CheckCircle2, XCircle } from "lucide-react";
import { authService } from "../services/authService";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("verifying"); // verifying, success, error
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verifyEmail = async () => {
      const token = searchParams.get("token");

      if (!token) {
        setStatus("error");
        setMessage("Verification token is missing");
        return;
      }

      try {
        const response = await authService.verifyEmail(token);
        const data = await response.json();

        if (response.ok) {
          setStatus("success");
          setMessage("Email verified successfully!");
        } else {
          setStatus("error");
          setMessage(data.error || "Verification failed");
        }
      } catch (error) {
        setStatus("error");
        setMessage("An error occurred during verification");
      }
    };

    verifyEmail();
  }, [searchParams]);

  const renderContent = () => {
    switch (status) {
      case "verifying":
        return (
          <div className="flex flex-col items-center space-y-4">
            <Loader2 className="animate-spin text-cyan-600" size={48} />
            <p className="text-gray-600">Verifying your email address...</p>
          </div>
        );

      case "success":
        return (
          <div className="flex flex-col items-center space-y-4">
            <CheckCircle2 className="text-green-500" size={48} />
            <p className="text-gray-600">{message}</p>
            <button
              onClick={() => navigate("/signin")}
              className="px-4 py-2 bg-cyan-600 text-white rounded-lg hover:bg-cyan-700"
            >
              Go to Sign In
            </button>
          </div>
        );

      case "error":
        return (
          <div className="flex flex-col items-center space-y-4">
            <XCircle className="text-red-500" size={48} />
            <p className="text-red-600">{message}</p>
            <div className="space-y-2">
              <button
                onClick={() => window.location.reload()}
                className="w-full px-4 py-2 bg-cyan-600 text-white rounded-lg hover:bg-cyan-700"
              >
                Try Again
              </button>
              <button
                onClick={() => navigate("/signin")}
                className="w-full px-4 py-2 border border-cyan-600 text-cyan-600 rounded-lg hover:bg-cyan-50"
              >
                Back to Sign In
              </button>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow p-8">
        <div className="text-center">
          <div className="mx-auto w-16 h-16 bg-cyan-100 rounded-full flex items-center justify-center mb-4">
            <Mail className="text-cyan-600" size={32} />
          </div>
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            Email Verification
          </h1>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
