import React from "react";

const PublicAccessibility = () => {
  return (
    <div className="w-full bg-white">
      {/* Header Section */}
      <div className="w-full bg-white py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Accessibility Statement
          </h1>
          <p className="text-xl text-gray-600">
            Our commitment to web accessibility
          </p>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto px-4 pb-16">
        <div className="prose max-w-none">
          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Our Commitment
            </h2>
            <p className="text-gray-600 mb-6">
              Shrtly (the company) makes reasonable efforts to provide its
              employees and the public, including persons with disabilities,
              with access to the shrtly.com website (&quot;Website&quot;) and
              its related information and services. The Website has been
              designed to reach the widest audience possible, but, if you have
              difficulty viewing the Website, using the Website, or performing
              any transaction through the Website, you are encouraged to contact
              us at{" "}
              <a
                href="mailto:support@shrtly.net"
                className="text-blue-600 hover:text-blue-800"
              >
                support@shrtly.net
              </a>{" "}
              or by{" "}
              <a href="/support" className="text-blue-600 hover:text-blue-800">
                opening a support ticket
              </a>
              .
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Accessibility Features
            </h2>
            <p className="text-gray-600 mb-4">
              Our website includes the following accessibility features:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-6">
              <li className="mb-2">Screen reader compatibility</li>
              <li className="mb-2">Keyboard navigation support</li>
              <li className="mb-2">Clear heading structure</li>
              <li className="mb-2">Descriptive alt text for images</li>
              <li className="mb-2">Sufficient color contrast</li>
              <li className="mb-2">
                Resizable text without loss of functionality
              </li>
              <li className="mb-2">Consistent navigation structure</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Standards Compliance
            </h2>
            <p className="text-gray-600 mb-6">
              We strive to meet WCAG 2.1 Level AA accessibility standards and
              regularly review our website to ensure we maintain and improve our
              accessibility features. Our development team follows best
              practices for accessible web design and regularly tests our
              website with various assistive technologies.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Feedback and Assistance
            </h2>
            <p className="text-gray-600 mb-4">
              We welcome your feedback on the accessibility of our website. If
              you:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-6">
              <li className="mb-2">Encounter any accessibility barriers</li>
              <li className="mb-2">Need assistance using our website</li>
              <li className="mb-2">Have suggestions for improvement</li>
              <li className="mb-2">
                Would like to request information in an alternative format
              </li>
            </ul>
            <p className="text-gray-600">
              Please contact us through one of the following methods:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-6">
              <li className="mb-2">
                Email:{" "}
                <a
                  href="mailto:accessibility@shrtly.net"
                  className="text-blue-600 hover:text-blue-800"
                >
                  accessibility@shrtly.net
                </a>
              </li>
              <li className="mb-2">
                Support Ticket:{" "}
                <a
                  href="/support"
                  className="text-blue-600 hover:text-blue-800"
                >
                  Open a Support Ticket
                </a>
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Continuous Improvement
            </h2>
            <p className="text-gray-600 mb-6">
              We are committed to maintaining and improving the accessibility of
              our website. We regularly review our website for accessibility
              issues and work to resolve them promptly. We provide accessibility
              training to our staff and consider accessibility in our
              development processes.
            </p>
            <p className="text-gray-600 mt-8">
              Last updated: December 12, 2024
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PublicAccessibility;
